<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Reviewing frameworks</h3>

	<p>One of the primary purposes of <span v-html="app_name"/> is to allow educators to collaboratively <b>review</b> standards...</p>
	<ul>
		<li>in the process of creating a new set of standards or revising standards</li>
		<li>to familiarize oneself with newly-published standards</li>
		<li>during curriculum planning</li>
		<li>etc.</li>
	</ul>
	<p>The primary workflow that <span v-html="app_name"/> provides for reviewing the standards in a framework revolves around <b>commenting</b> functionality. The general outline of the workflow is as follows:</p>
	<ol>
		<li>Organize reviewers into one or more groups who will work together to review all or part of the framework.</li>
		<li>Ensure that all members of all groups can <span v-html="link('signing_in', 'sign in')"></span> to <span v-html="app_name"/></li>
		<li>If the framework is currently <span v-html="link('adoption_status', 'private')"></span>, grant each reviewer <span v-html="link('user_roles_admin', 'access')"></span> to <span v-html="link('roles_framework', 'review')"></span> the private framework.</li>
		<li>One member of each group of reviewers should be designated to take the lead for the group, and should create a <span v-html="link('comment_groups', 'comment group')"></span>, name it appropriately, and add other members of the reviewer group to the comment group.</li>
		<li>Now each member of each group can sign in to <span v-html="app_name"/>, review the framework, and add <span v-html="link('add_comments', 'comments')"></span> to any standards in the framework, tagging the comments to their group.</li>
		<li>As everyone is reviewing, they can <span v-html="link('show_all_comments', 'view all comments')"></span> submitted by their group together in a table, and can reply to other group members’ comments if they like.</li>
		<li>If the framework is being reviewed for revision, any comment may include <span v-html="link('suggest_edits', 'suggested edits')"></span> to a standard. Then once the group(s) has/have completed their review, one person can be granted <span v-html="link('roles_framework', 'edit rights')"></span> to the framework, and can go through and accept the suggested edits.</li>
		<li>One edits are complete, the framework can be set to a “Public” <span v-html="link('adoption_status', 'adoption status')"></span>, so that all <span v-html="app_name"/> users (whether signed in or not) can view it.</li>
	</ol>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	